export const menuItems = [
  {
    path: "/",
    text: "home",
  },
  {
    path: "/about",
    text: "about us",
  },
  // {
  //   path: "/projects",
  //   text: "projects",
  // },
  // {
  //   path: "/products",
  //   text: "products",
  // },
  // {
  //   path: "/blogs",
  //   text: "Blog",
  // },
  {
    path: "/contact",
    text: "contact",
  },
]
